import { SettingsApiService } from '@api/settings/settings.api';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SmartChargingSettings } from '../../types/Setting';

@Injectable()
export class SettingsRepositoryService {
  public constructor(private readonly settingsApi: SettingsApiService) {}

  public getSettings(): Observable<SmartChargingSettings> {
    return this.settingsApi.getSettings('smartCharging');
  }
}
