import { Inject, Injectable, OnDestroy } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { distinct, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { WINDOW } from '@providers/window.provider';
import { SentryUtils } from '../utils/sentry/sentry-utils';

@Injectable()
export class SentryService implements OnDestroy {
  private subscription!: Subscription;

  public constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    @Inject(WINDOW) private window: Window,
  ) {}

  public init(): void {
    if (!SentryUtils.isSentryEnabled) {
      return;
    }

    try {
      this.initLocationListener();
      this.updateUserInContext();
      this.initLocalStorageListener();
    } catch (e) {
      console.error('Error while initializing Sentry', e);
    }
  }

  private initLocationListener(): void {
    this.subscription = this.router.events
      .pipe(
        map(() => this.window?.location.host ?? ''),
        distinct(),
      )
      .subscribe((host) => {
        const split = host.split('.');
        if (split.length === 1) {
          Sentry.setContext('tenant', { tenantDomain: 'default' });
          Sentry.setTag('tenant', 'default');
        } else {
          Sentry.setContext('tenant', { tenantDomain: split[0] });
          Sentry.setTag('tenant', split[0]);
        }
      });
  }

  private initLocalStorageListener(): void {
    if (this.window?.addEventListener) {
      this.window.addEventListener(
        'storage',
        () => {
          this.updateUserInContext();
        },
        false,
      );
    }
  }

  private updateUserInContext(): void {
    const userStr = this.localStorageService.getItem('user');

    if (!userStr) {
      Sentry.setUser(null);
      return;
    }

    const user = JSON.parse(userStr);

    Sentry.setTags({
      id: user.id,
      language: user.language,
      role: user.role,
      tenantId: user.tenantID,
      tenantName: user.tenantName,
      tenantSubdomain: user.tenantSubdomain,
      currency: user.currency,
    });

    Sentry.setUser(user);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
