import { forkJoin, Observable, tap } from 'rxjs';
import { inject, provideAppInitializer } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { LocaleService } from '@services/locale.service';
import { CentralServerService } from '../services/central-server.service';
import { EnvironmentService } from '../services/environment.service';
import { MixpanelService } from '../services/mixpanel.service';

export const appInitProvider = provideAppInitializer(() => {
  const centralServerService = inject(CentralServerService);
  const environmentService = inject(EnvironmentService);
  const mixpanelService = inject(MixpanelService);
  const authConfig = inject(AuthClientConfig);
  const localeService = inject(LocaleService);

  return forkJoin([
    environmentService.init().pipe(
      tap((config) => {
        mixpanelService.init();
        authConfig.set(config.auth0Config);
      }),
    ),
    new Observable((observer) => {
      centralServerService.initUserToken();
      localeService.initLocale();
      observer.next();
      observer.complete();
    }),
  ]);
});
