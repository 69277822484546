import { Component, OnInit } from '@angular/core';
import { MarkdownComponent } from 'ngx-markdown';
import { SpinnerService } from '../services/spinner.service';
import { ReleaseNoteApi } from '../api/release-note/release-note.api';
import { MessageService } from '../services/message.service';

@Component({
  templateUrl: 'release-notes.component.html',
  styleUrls: ['release-notes.component.scss'],
  imports: [MarkdownComponent],
})
export class ReleaseNotesComponent implements OnInit {
  public releaseContent = '';

  public constructor(
    private releaseNoteApi: ReleaseNoteApi,
    private spinner: SpinnerService,
    private messageService: MessageService,
  ) {}

  public ngOnInit(): void {
    this.spinner.show();
    this.releaseNoteApi.getReleaseNote().subscribe(
      (data: string) => {
        this.releaseContent = data;
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.messageService.showErrorMessage('general.error_backend');
      },
    );
  }
}
