import 'chartjs-adapter-date-fns';

import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Chart, registerables } from 'chart.js';

import { RouterOutlet } from '@angular/router';
import { CONNECTOR_ALL_TYPES_MAP } from './shared/model/charging-stations.model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  imports: [RouterOutlet],
})
export class AppComponent {
  public title = 'VEV-IQ';

  public constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      'charging-socket',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/charging-socket.svg'),
    );
    iconRegistry.addSvgIcon('charger', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/charger.svg'));
    iconRegistry.addSvgIcon('socket', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/socket.svg'));
    iconRegistry.addSvgIcon('truck', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/truck.svg'));
    iconRegistry.addSvgIcon('battery', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/battery.svg'));
    iconRegistry.addSvgIcon('power', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/power.svg'));
    iconRegistry.addSvgIcon('clock', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/clock.svg'));
    iconRegistry.addSvgIcon('watch', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/watch.svg'));
    for (const connectorMap of CONNECTOR_ALL_TYPES_MAP) {
      if (connectorMap.svgIconName) {
        iconRegistry.addSvgIcon(
          connectorMap.svgIconName,
          sanitizer.bypassSecurityTrustResourceUrl(connectorMap.svgIconFile),
        );
      }
    }
    Chart.register(...registerables);
  }
}
