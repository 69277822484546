import { importProvidersFrom } from '@angular/core';
import { ApiModule } from '@api/api.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material-dayjs';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { RepositoryModule } from '@repository/repository.module';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateLoader, TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { AuthModule } from '@auth0/auth0-angular';
import { WINDOW_PROVIDERS } from '@providers/window.provider';
import { EnvironmentService } from '@services/environment.service';
import { CentralServerService } from '@services/central-server.service';
import { AuthorizationService } from '@services/authorization.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { ComponentService } from '@services/component.service';
import { SpinnerService } from '@services/spinner.service';
import { LocaleService } from '@services/locale.service';
import { LocalStorageService } from '@services/local-storage.service';
import { MessageService } from '@services/message.service';
import { WindowService } from '@services/window.service';
import { StripeService } from '@services/stripe.service';
import { ReleaseNoteApi } from '@api/release-note/release-note.api';
import { SentryService } from '@services/sentry.service';
import { MixpanelService } from '@services/mixpanel.service';
import { envProvider } from '@providers/env.provider';
import { appInitProvider } from '@utils/app-factory';
import { matTabsConfigProvider } from '@providers/angular-material.provider';
import { Loader } from '@googlemaps/js-api-loader';
import { SentryUtils } from '@utils/sentry/sentry-utils';
import { PlatformService } from '@services/platform.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { httpInterceptorProviders } from './app/interceptors';
import { CustomTranslateDefaultParser } from './app/i18n/custom-translate.parse';
import { I18TranslateLoader } from './app/i18n/i18-translate.loader';
import { SidebarModule } from './app/sidebar/sidebar.module';
import { MaterialModule } from './app/material/material.module';
import { AppRoutingModule } from './app/app-routing.module';

export const appProviders = [
  importProvidersFrom(
    ApiModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    MarkdownModule.forRoot(),
    MaterialModule,
    NgxCaptchaModule,
    NgxDaterangepickerMd.forRoot(),
    NgxGpAutocompleteModule,
    ReactiveFormsModule,
    RepositoryModule,
    RouterModule,
    SidebarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: I18TranslateLoader,
        deps: [HttpClient],
      },
      parser: {
        provide: TranslateParser,
        useClass: CustomTranslateDefaultParser,
      },
      defaultLanguage: 'en',
    }),
    AuthModule.forRoot(),
  ),
  WINDOW_PROVIDERS,
  EnvironmentService,
  CentralServerService,
  AuthorizationService,
  NavigationService,
  ComponentService,
  SpinnerService,
  LocaleService,
  LocalStorageService,
  MessageService,
  TranslateService,
  WindowService,
  StripeService,
  ReleaseNoteApi,
  SentryService,
  MixpanelService,
  envProvider,
  appInitProvider,
  matTabsConfigProvider,
  {
    provide: Loader,
    useValue: new Loader({
      apiKey: 'AIzaSyAlrUfxOfuXFDifAw3MtyxYWf36KnvWjCs',
      libraries: ['places'],
    }),
  },
  ...SentryUtils.getSentryProviders(),
  httpInterceptorProviders,
  provideHttpClient(withInterceptorsFromDi(), withFetch()),
  PlatformService,
  provideAnimations(),
];

export const appConfig = {
  providers: appProviders,
};
