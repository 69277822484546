import { bootstrapApplication } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';

import localeCs from '@angular/common/locales/cs'; // ACHTUNG - cz does not exists ==> cs-CZ
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en';
import localeEnAU from '@angular/common/locales/en-AU';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';
import { appConfig } from './app.config';
import { AppComponent } from './app/app.component';
import { SentryUtils } from './app/utils/sentry/sentry-utils';

registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localePt);
registerLocaleData(localeIt);
registerLocaleData(localeCs);
registerLocaleData(localeEnAU);
registerLocaleData(localeEl);

if (typeof document !== 'undefined') {
  // Bootstrap can only be loaded in the browser and not server-side
  import('bootstrap')
    .then(() => import('bootstrap-notify'))
    .catch((err) => {
      console.error('Error loading Bootstrap:', err);
    });

  SentryUtils.init();
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
