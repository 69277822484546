import { ErrorHandler, Provider, EnvironmentProviders } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

export class SentryUtils {
  private static readonly SENTRY_BREADCRUMB_CLICK_CATEGORY = 'ui.click';
  private static readonly SENTRY_BREADCRUMB_INPUT_CATEGORY = 'ui.click';
  public static isSentryEnabled = true;

  public static init(): void {
    if (!SentryUtils.isSentryEnabled) {
      return;
    }

    const envs = {
      'dev-vev-iq.com': 'dev',
      'qa-vev-iq.com': 'qa',
      'vev-iq.com': 'prod',
    } as const;

    Sentry.init({
      dsn: 'https://b65cee226fc73b531809af31340e8f54@o4506631232094208.ingest.sentry.io/4506660194811904',
      tracePropagationTargets: ['localhost', /^https:\/\/(dev-|qa-)?vev-iq.com\/v1/],
      integrations: [Sentry.browserTracingIntegration()],
      normalizeDepth: 7,
      tracesSampleRate: 0.1,
      attachStacktrace: true,
      // Decide environment based on second-level domain
      environment: envs[window.location.hostname.split('.').slice(-2).join('.')] ?? 'local',
      beforeBreadcrumb(breadcrumb, hint) {
        try {
          if (
            [SentryUtils.SENTRY_BREADCRUMB_CLICK_CATEGORY, SentryUtils.SENTRY_BREADCRUMB_INPUT_CATEGORY].includes(
              breadcrumb.category,
            )
          ) {
            breadcrumb.data = {
              ...breadcrumb.data,
              ...SentryUtils.getTextAndNodeNameOfElement(hint.event.target),
            };
          }
        } catch (e) {
          Sentry.captureException(e);
        }
        return breadcrumb;
      },
    });
  }

  private static getTextAndNodeNameOfElement(element: HTMLElement | HTMLInputElement): {
    text: string;
    nodeName: string;
  } {
    const text =
      element.innerText ||
      element.textContent ||
      (element instanceof HTMLInputElement ? element.placeholder : undefined);

    if (!text) {
      return SentryUtils.getTextAndNodeNameOfElement(element.parentElement);
    }

    return { text, nodeName: element.nodeName };
  }

  public static getSentryProviders(): (Provider | EnvironmentProviders)[] {
    if (!SentryUtils.isSentryEnabled) {
      return [];
    }

    return [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
    ];
  }
}
