{
  "name": "ev-dashboard",
  "description": "Dashboard for monitoring the EV Charging Infrastructure",
  "homepage": "https://github.com/VEV-platform-services/vev-dashboard",
  "version": "2.8.8",
  "engines": {
    "node": "20.9.0",
    "npm": "10.x.x"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/VEV-platform-services/vev-dashboard.git"
  },
  "author": "VEV Platform services <platform.services@vev.com>",
  "license": "Apache-2.0",
  "licenses": [
    {
      "type": "Apache-2.0",
      "url": "http://www.apache.org/licenses/LICENSE-2.0"
    }
  ],
  "importSort": {
    ".js, .jsx, .es6, .es, .mjs": {
      "parser": "babylon",
      "style": "module",
      "options": {}
    },
    ".ts, .tsx": {
      "parser": "typescript",
      "style": "module",
      "options": {}
    }
  },
  "scripts": {
    "git:sinit": "git submodule update --init --recursive --force",
    "git:sdiff": "git diff && git submodule foreach 'git diff'",
    "git:supdate": "git submodule update --remote --recursive --merge",
    "git:spush": "git push --recurse-submodules=on-demand",
    "git:sreset": "git submodule foreach 'git reset --hard'",
    "ng": "ng",
    "start": "npm version && NG_HMR_TEMPLATES=1 ng serve --configuration=development",
    "start:ssl": "npm version && ng serve --configuration=development --ssl true --ssl-key ./ssl/localhost.key --ssl-cert ./ssl/localhost.cert",
    "start:prod": "npm version && cross-env NODE_OPTIONS=\"--max-old-space-size=4096\" ng serve --configuration production",
    "start:prod:ssl": "npm version && cross-env NODE_OPTIONS=\"--max-old-space-size=4096\" ng serve --configuration production --ssl true --ssl-key ./ssl/localhost.key --ssl-cert ./ssl/localhost.cert",
    "build": "npm run build:dev",
    "build:prod": "npm version && cross-env NODE_OPTIONS=\"--max-old-space-size=4096\" ng build --configuration production && npm run sentry:sourcemaps",
    "build:prod:stats": "npm version && cross-env NODE_OPTIONS=\"--max-old-space-size=4096\" ng build --configuration production --stats-json",
    "build:dev": "npm version && ng build --configuration=development --stats-json",
    "lint": "ng lint",
    "eslint:fix": "ng lint --fix=true",
    "eslint:html": "npm run eslint:file && npm run eslint:report",
    "eslint:file": "mkdir -p test/results-eslint && ng lint --format json --silent > test/results-eslint/jsonOutput.json",
    "eslint:report": "ng-lint-report --jsonfile test/results-eslint/jsonOutput.json --output test/results-eslint",
    "clean-install": "rimraf package-lock.json && rimraf node_modules && npm cache clear --force && npm install --force",
    "npm-check": "npm-check --skip-unused",
    "ng:update": "ng update",
    "angular-update": "ng update @angular/cli @angular/core",
    "material-update": "ng update @angular/material",
    "angular11-update": "ng update @angular/core@11 @angular/cli@11",
    "material11-update": "ng update @angular/material@11",
    "import-sort": "npx import-sort-cli --write '{src,test}/**/*.ts{,x}'",
    "check:i18n": "cross-env TS_NODE_FILES=true ts-node-dev --project tsconfig-i18n.json --files src/app/I18nChecker.ts",
    "test": "set NODE_OPTIONS=--max_old_space_size=8192 && ng test --watch=false --source-map=false",
    "test:watch": "ng test",
    "test:e2e": "npx playwright test",
    "test:e2e-show-report": "npx playwright show-report",
    "sentry:sourcemaps": "[ \"$DISABLE_UPLOAD_SOURCE_MAPS\" != \"true\" ] && sentry-cli sourcemaps inject --org vev-platform-services --project vev-dashboard ./dist/ && sentry-cli sourcemaps upload --org vev-platform-services --project vev-dashboard ./dist/ || true",
    "sops:decrypt": "sops -d env.local.dotenv > .env.local",
    "npm:init": "npm run sops:decrypt && node --env-file .env.local scripts/setup.mjs",
    "send-slack-message": "ts-node scripts/send-slack-message.mjs",
    "ci:setup": "tsx e2e/setup/main-setup.ts",
    "ci:teardown": "tsx e2e/setup/main-global-teardown.ts",
    "storybook": "ng run ev-dashboard:storybook",
    "build-storybook": "ng run ev-dashboard:build-storybook"
  },
  "dependencies": {
    "@angular-magic/ngx-gp-autocomplete": "^2.0.2",
    "@angular/animations": "19.1.1",
    "@angular/cdk": "19.1.0",
    "@angular/common": "19.1.1",
    "@angular/compiler": "19.1.1",
    "@angular/core": "19.1.1",
    "@angular/forms": "19.1.1",
    "@angular/google-maps": "^19.1.0",
    "@angular/localize": "19.1.1",
    "@angular/material": "19.1.0",
    "@angular/platform-browser": "19.1.1",
    "@angular/platform-browser-dynamic": "19.1.1",
    "@angular/platform-server": "19.1.1",
    "@angular/router": "19.1.1",
    "@angular/ssr": "^19.1.1",
    "@auth0/angular-jwt": "^5.2.0",
    "@auth0/auth0-angular": "^2.2.3",
    "@danielmoncada/angular-datetime-picker": "^19.0.0",
    "@googlemaps/js-api-loader": "^1.16.6",
    "@ng-bootstrap/ng-bootstrap": "^18.0.0",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@popperjs/core": "^2.11.8",
    "@sentry/angular": "^9.1.0",
    "@sentry/cli": "^2.41.1",
    "@stripe/stripe-js": "^4.1.0",
    "bootstrap": "^5.3.3",
    "bootstrap-material-design": "^4.1.3",
    "bootstrap-notify": "^3.1.3",
    "chart.js": "^4.4.3",
    "chartjs-adapter-date-fns": "^3.0.0",
    "date-fns": "^3.6.0",
    "dayjs": "^1.11.10",
    "deep-object-diff": "^1.1.9",
    "express": "^4.21.2",
    "file-saver": "^2.0.5",
    "http-status-codes": "^2.2.0",
    "jasmine-auto-spies": "^8.0.1",
    "jquery": "^3.6.0",
    "mixpanel-browser": "^2.55.0",
    "ng2-file-upload": "^7.0.1",
    "ngx-captcha": "^13.0.0",
    "ngx-csv-parser": "^2.0.0",
    "ngx-daterangepicker-material-dayjs": "github:VEV-platform-services/ngx-daterangepicker-material-dayjs",
    "ngx-markdown": "^19.0.0",
    "ngx-skeleton-loader": "^9.0.0",
    "parse-domain": "^8.0.2",
    "powerbi-client-angular": "^3.0.5",
    "rxjs": "^7.5.6",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "validator": "^13.12.0",
    "zone.js": "~0.15.0"
  },
  "optionalDependencies": {
    "bufferutil": "^4.0.8",
    "utf-8-validate": "^6.0.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.1.1",
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/eslint-plugin": "^19.0.2",
    "@angular-eslint/eslint-plugin-template": "^19.0.2",
    "@angular-eslint/schematics": "^19.0.2",
    "@angular-eslint/template-parser": "^19.0.2",
    "@angular/cli": "19.1.1",
    "@angular/compiler-cli": "19.1.1",
    "@chromatic-com/storybook": "^3.2.2",
    "@compodoc/compodoc": "^1.1.26",
    "@faker-js/faker": "^8.4.1",
    "@playwright/test": "^1.48.2",
    "@slack/web-api": "^7.0.2",
    "@storybook/addon-essentials": "^8.4.7",
    "@storybook/addon-interactions": "^8.4.7",
    "@storybook/addon-onboarding": "^8.4.7",
    "@storybook/angular": "^8.4.7",
    "@storybook/blocks": "^8.4.7",
    "@storybook/test": "^8.4.7",
    "@types/bootstrap": "^5.2.10",
    "@types/express": "^4.17.17",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.4",
    "@types/jquery": "^3.5.29",
    "@types/mixpanel-browser": "^2.49.1",
    "@types/node": "^18.18.0",
    "@vev-platform-services/e2e-volt-kit": "^1.2.13",
    "@vev-platform-services/eslint-config": "^1.1.2",
    "allure-playwright": "^3.0.9",
    "axios": "^1.6.8",
    "cross-env": "^7.0.3",
    "eslint": "^8.57.0",
    "eslint-plugin-storybook": "^0.11.1",
    "jasmine-core": "~5.1.2",
    "junitxml-to-javascript": "^1.1.4",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "karma-junit-reporter": "^2.0.1",
    "karma-spec-reporter": "^0.0.36",
    "ng-lint-report": "^0.2.0",
    "npm-check": "^6.0.1",
    "prettier": "3.2.5",
    "storybook": "^8.4.7",
    "ts-node": "~10.9.2",
    "ts-node-dev": "^2.0.0",
    "tsx": "^4.19.2",
    "typescript": "~5.7.3"
  },
  "overrides": {
    "whatwg-url": "14.0.0",
    "ng2-file-upload": {
      "@angular/common": "$@angular/common",
      "@angular/core": "$@angular/core"
    }
  }
}
