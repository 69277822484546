import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  template: `
    <div class="browser-not-supported-message-container">
      <span class="browser-not-supported-message">{{ 'general.browser_not_supported' | translate }}</span>
    </div>
  `,
  styleUrls: ['browser-not-supported.component.scss'],
  imports: [TranslateModule],
})
export class BrowserNotSupportedComponent {}
