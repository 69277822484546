import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SmartChargingSettings } from '../../types/Setting';
import { RESTServerRoute } from '../../types/Server';

@Injectable()
export class SettingsApiService {
  public constructor(private httpClient: HttpClient) {}

  public getSettings<T extends SmartChargingSettings>(identifier: 'smartCharging'): Observable<T> {
    return this.httpClient.get<T>(RESTServerRoute.REST_SETTINGS, {
      params: {
        Identifier: identifier,
      },
    });
  }
}
